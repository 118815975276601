import Rest from '../Rest'
import { snakeCase } from 'lodash'

export default class SuggestionService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/suggestion';
  public max: Number;
  public property: string|null;
  public model: string;
  public with: Array<string>;

  constructor(resource: string, http = null, vs: any, max=5, prop=null, model='', _with=[]) {
    super(
      resource,
      http,
      vs
    )
    this.max = max
    this.property = prop
    this.model = model
    this.with = _with
  }

  static buildOnModel(model, property, max, _with, vs=null) {
    return new this(this.resource, null, vs, max, property, model, _with)
  }

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }  

  fetchSuggestion(term, prop = null, max = null) {
    const model = snakeCase(this.model.toString())
    return this.get(`/${model}`, {
      term,
      property: prop || this.property,
      max: max || this.max,
      with: this.with
    }).then((data) => {
      return data
    })
  }
}