import DataSeries from './DataSeries'

/**
 * Class representing an e-chart data series as needed by the QuestionnairePerformanceReport component.
 */
export default class QuestionPerformanceDataSeries implements DataSeries {
  questionnaireName: String
  data: Array<any>
  type: String = 'line'

  constructor(questionnaireName, data) {
    this.data = data
    this.questionnaireName = questionnaireName
  }

  /**
   * Transforms the data into its normalized form, ie: An array of numbers.
   * The type of transformation/normalization is taken from the "kind" property
   * passed through the param object.
   * @param param
   * @returns
   */
  normalized(param = { kind: 'score' }): Array<number> {
    return this.data.map((questionReport) => {
      if ('kind' in param) {
        /*
                When normalizing the data, since it might be composed of any
                sort of object imaginable, always verify if the properties you require
                to use actually exist in the object.
                */
        if (
          param.kind === 'score' &&
          'score_average' in questionReport &&
          'score_max' in questionReport
        ) {
          console.log('Calculating normalization based on score')
          if (!questionReport.score_max) {
            return 0
          }
          return (
            (questionReport.score_average / questionReport.score_max || 0) * 100
          )
        } else if (
          param.kind === 'correction' &&
          'correction_true' in questionReport &&
          'correction_count' in questionReport
        ) {
          // kind === 'correction'
          console.log('Calculating normalization based on correction')
          if (!questionReport.correction_count) {
            return 0
          }
          return (
            (questionReport.correction_true / questionReport.correction_count ||
              0) * 100
          )
        } else {
          return 0
        }
      } else {
        throw 'Tipo de normalização inválida'
      }
    })
  }

  legend() {
    return this.questionnaireName
  }

  getType() {
    return this.type
  }

  /**
   * Factory method for making a mockup based on a questionnaire.
   * @param questionnaire
   * @returns QuestionPerformanceDataSeries
   */
  static mocked(questionnaire) {
    const fields: Array<any> = JSON.parse(questionnaire.data).fields
    const data: Array<any> = []
    fields.forEach((field) => {
      const score = Math.floor(Math.random() * 5)
      const point: any = {
        question_id: field.id,
        score_max: score,
        score_sum: parseFloat(Math.random().toFixed(2)) * score * 10,
        score_count: Math.floor(Math.random() * 500),
        score_average: parseFloat(Math.random().toFixed(2)) * score,
        correction_true: Math.floor(Math.random() * 500),
        correction_count: 500,
        answer_count: Math.floor(Math.random() * 500),
      }
      data.push(point)
    })

    return new this(questionnaire.name, data)
  }
}
