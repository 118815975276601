import { get, isArray } from 'lodash'
import Vue from 'vue'
import QuestionPerformanceDataSeries from './e-chart/QuestionPerformanceDataSeries'

/**
 * These two first classes are used to compose the necessary structure in the 
 * third class, QuestionsPerformanceReport.
 * QuestionsPerformanceReport is used to represent the graphic in table form
 * of the QuestionnairePerformanceReport component.
 */
class QuestionnairePerformance {
    
    id: number
    name: string
    value: number

    constructor(id: number, name: string, value: number) {
        this.id = id
        this.name = name
        this.value = value
    }
}

class QuestionPerformanceCollection {
    questionName: string
    questionId: string
    questionData: any
    questionnaires: Array<QuestionnairePerformance>

    constructor(question:any) {
        this.questionName = Vue.prototype.shortTitle(question.data.title)
        this.questionId = question.id
        this.questionData = question.data
        this.questionnaires = []
    }
}

export default class QuestionsPerformanceReport {
    questions: Array<QuestionPerformanceCollection>

    constructor() {
        this.questions = []
    }

    findQuestionnairePosition(questionPosition, id): number
    {
        return this.questions[questionPosition].questionnaires.findIndex((questionnaire)=> id === questionnaire.id)
    }

    addQuestionnaire(questionnaire) {
        this.questions.forEach( (question, index) => {
            const questionnairePosition = this.findQuestionnairePosition(index, questionnaire.id)
            if (questionnairePosition === -1) {
                this.questions[index].questionnaires.push(
                    new QuestionnairePerformance(questionnaire.id, questionnaire.name, 0)
                )
            }
        })
    }

    removeQuestionnaire(questionnaire) {
        this.questions.forEach( (question, index) => {
            const questionnairePosition = this.findQuestionnairePosition(index, questionnaire.id)
            if (questionnairePosition !== -1) {
                this.questions[index].questionnaires.splice(questionnairePosition, 1)
            }
        })
    }

    addQuestionDataSeries(kind, questionnaire, questionDataSeries: QuestionPerformanceDataSeries) {
        questionDataSeries.normalized({kind: kind}).forEach((normalizedValue, position) => {
            if (position+1 <= this.questions.length) { 
                // Prevents the method from crashing when adding a questionnaire question report data
                // series that has more questions than the base amount of questions in this QuestionPerformanceReport
                const questionnairePosition = this.findQuestionnairePosition(position, questionnaire.id)
                if (questionnairePosition !== -1) {
                    this.questions[position].questionnaires[questionnairePosition].value = normalizedValue
                }
            }
        })
    }

    /**
     * Makes a QuestionsPerformanceReport based on a questionnaire, 
     * that is: setups the necessary questions.
     * @param questionnaire 
     * @returns 
     */
    static fromQuestionnaire(questionnaire: any) {
        let decodedData: any = {}
        try {
            decodedData = JSON.parse(questionnaire.data)
        } catch (error) {
            throw error
        }

        if ('fields' in decodedData && isArray(decodedData.fields)) {
            const questions: Array<QuestionPerformanceCollection> = []
            decodedData.fields.forEach((field) => {
                const type = get(field, 'type', null)
                if (type !== null && type !== 'section') {
                    questions.push( new QuestionPerformanceCollection(field))
                }
            })
            const questionnaireQuestionPerformanceReport = new this()
            questionnaireQuestionPerformanceReport.questions = questions
            
            return questionnaireQuestionPerformanceReport
        } else {
            throw 'Invalid Questionnaire Data Fields'
        }
        
    }
}