<template>
  <vx-card>
    <div class="grid grid-cols-12 gap-4">
      <div v-if="showEmptyGraphWarning" class="col-span-12">
        <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('nao-existem-dados-para-esta-consulta') }}
        </vs-alert>
        <vs-alert
        v-if="someQuestionnaireNoResume"
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('nao-e-possivel-calcular-o-desempenho-par') }}
        </vs-alert>
      </div>
      <div class="col-span-12">
        <fieldset class="p-2 pt-0 border border-grey">
          <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
          <div class="grid items-end p-2 gap-4">
            <tag-suggestion
              :max="20"
              class="row-start-1"
              column="name,description"
              model="ContentQuestionnaire"
              :label="$t('questionarios')"
              :with="['answers.user']"
              :modelToTagMapping="
                (model) => ({
                  text: model.name,
                  data: model
                })
              "
              v-model="questionnaires"
              ref="select_questionnaire"
              placeholderText="Digite o nome do questionário"
            />
            <div class="row-start-2 flex items-end gap-2">
              <user-repository
                class="flex-grow"
                :userRepository="userRepository"
                v-model="selectedUsers"
              />
              <div class="flex flex-row flex-wrap place-self-center gap-1">
                <vs-label class="flex-grow w-full text-sm pb-2">{{ $t('calculo-porcentagem') }}</vs-label>
                <vs-label :class="averageKind ? '' : 'text-primary'">{{ $t('pontuacao-media') }}</vs-label>
                <vs-switch class="bg-primary" v-model="averageKind" @input="updateGrid"/>
                <vs-label :class="averageKind ? 'text-primary' : ''">{{ $t('quantidade-de-acertos') }}</vs-label>
              </div>
              <div>
                <vs-button :disabled="!validFilters" class="py-auto px-4 mr-2" @click="updateData">{{ $t('filtrar') }}</vs-button>
                <vs-button class="py-auto px-4" type="border" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
              </div>
            </div>
            
          </div>
        </fieldset>
      </div>
      <div class="col-span-12" v-if="echartData && echartData.series && echartData.series.length > 0">
        <e-charts v-if="echartData" autoresize :options="echartData" ref="graph" auto-resize />
      </div>
      <div class="col-span-12 flex flex-col gap-4" v-if="questionPerformanceReport && questionPerformanceReport.questions.length > 0 && !this.graphIsEmpty">
        <vs-table :data="questionPerformanceReport.questions">
          <template slot-scope="{data}">
            <vs-tr :key="question.questionId" v-for="(question, position) in data">
              <vs-td>
                <div :class="getQuestionReportRowClass(question)">
                  <div class="flex flex-row gap-2 w-full bg-faint-grey p-3 question-title">
                    <label>{{ $t('questao-position-1', [position + 1]) }} </label>
                    <div v-html-safe="shortTitle(question.questionData.title)" class="editor-content ck-content"></div>
                  </div>
                  <div class="flex w-full gap-2 px-3 pt-1" :key="position" v-for="(questionnaire, position) in question.questionnaires">
                    <label
                      class="font-semibold truncate w-96"
                      :style="`color: ${echartData.color[position]}`"
                      v-tooltip.auto="{
                        content: questionnaire.name,
                        delay: { show: 100, hide: 100 }
                      }"
                    >
                      {{questionnaire.name}}
                    </label>

                    <label>{{formatFloat(questionnaire.value)}}%</label>
                  </div>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>
  </vx-card>
</template>

<script>
import ReportService from '@/services/api/ReportService'
import QuestionPerformanceDataSeries from '@/models/e-chart/QuestionPerformanceDataSeries'
import QuestionsPerformanceReport from '@/models/QuestionsPerformanceReport'
import ObjectRepository from '@/models/ObjectRepository'
import ECharts from 'vue-echarts/components/ECharts'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/title'
import 'echarts/lib/chart/line'
import TagSuggestion from '@/components/TagSuggestion.vue'
import PercentualLineGraph from '@/models/e-chart/PercentualLineGraph'
import { arraySort } from '@/util/Util'
import { isArray } from 'lodash'
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import UserRepository from '@/components/UserRepository.vue'

export default {
  components: { ECharts, TagSuggestion, UserRepository},

  data: () => ({
    service: null,
    questionnaireService: null,
    questionnaires: [],
    selectedUsers: [],
    percentualLineGraph: null,
    userRepository: null,
    questionPerformanceReport: null,
    averageKind: false,
    someQuestionnaireNoResume: false
  }),

  watch: {
    averageKind(val) {
      if (this.percentualLineGraph) {
        this.percentualLineGraph.params.kind = val ? 'correction' : 'score'
      }
      this.filtersChanged({averageKind: val})
    },
    questionnaires(questionnaires) {
      if (this.questionnaireService && questionnaires && isArray(questionnaires) && questionnaires.length > 0) {
        /**
         * After a questionnaire is added or removed, we need to update the user select listing
         * and get these questionnaire's data to cache it in the store.
         * 
         * For that we push every request, since there can be more than one questionnaire selected,
         * to an array then solve all of them at once, to guarantee that all are resolved
         * and done before going through each of their results.
         */
        const participantRequests = []
        const dataRequests = []

        questionnaires.forEach((questionnaireTagData) => {
          participantRequests.push(this.questionnaireService.getParticipants(questionnaireTagData.data.id, 3))
          dataRequests.push(this.questionnaireService.read(questionnaireTagData.data.id))
        })

        Promise.all(participantRequests).then(
          (results) => {
            const users = []

            results.forEach((participants) => {
              participants.forEach((participant) => {
                // Do not add user to the array if it was already added
                const exists = users.findIndex((user) => user.id === participant.id) !== -1
                if (!exists) users.push(participant)
              })
            })

            this.userRepository.setObjects(users)
            this.userRepository.sortObjects('name')
          }
        )

        Promise.all(dataRequests).then(
          (questionnaires) => {
            questionnaires.forEach((questionnaire) => {
              // Update the tagData.data only if it exists/isFound
              const position = this.questionnaires.findIndex(
                (questionnaireTagData) => questionnaireTagData.data.id === questionnaire.id
              )

              if (position !== -1) this.questionnaires[position].data.data = questionnaire.data
            })
          }
        )
      } else {
        this.userRepository.clearObjects()
      }
      this.filtersChanged({questionnaires: questionnaires})
    },
    selectedUsers(users) {
      this.filtersChanged({users: users})
    }
  },

  computed: {
    graphIsEmpty() {
      if (this.hasData) {
        let foundFilled = false
        this.echartData.series.forEach((series) => {
          if ('data' in series && series.data.length > 0) {
            foundFilled = true
          }
        })
        return !foundFilled
      }

      return false
    },
    validFilters() {
      return this.questionnaires && this.questionnaires.length > 0
    },
    showEmptyGraphWarning() {
      return this.hasData && this.graphIsEmpty
    },
    hasData() {
      return this.echartData && this.echartData.series.length > 0
    },
    echartData() {
      if (this.percentualLineGraph && 'option' in this.percentualLineGraph) {
        return this.percentualLineGraph.option
      }
      return null
    },
    incompatibleQuestionnaires() {
      if (this.questionnaires.length > 0) {

        const firstFieldCount = JSON.parse(this.questionnaires[0].data.data).fields.length
        return this.questionnaires.some((questionnaireTag) => {
          
          let currentFieldCount = 0
          try {
            currentFieldCount = JSON.parse(questionnaireTag.data.data).fields.length
          } catch (error) {
            console.error('Invalid questionnaire data json during questionnaire report compatibility verification')
          }
          
          return firstFieldCount !== currentFieldCount
        })
      }
      return false
    }
  },

  methods: {
    addQuestionnaire(questionnaire) {
      const lastQuestionnaire = this.questionnaires[this.questionnaires.length - 1]

      this.questionPerformanceReport.addQuestionnaire(questionnaire)

      this.service.getPerformanceReport(questionnaire.id, this.selectedUsers).then(
        (data) => {
          if (questionnaire.id === lastQuestionnaire.data.id) {
            this.$vs.loading.close()
          }

          let dataSeries = []

          if ('no_resume' in data && data.no_resume) {
            this.someQuestionnaireNoResume = data.no_resume
            dataSeries = new QuestionPerformanceDataSeries(questionnaire.name, [])
          } else {
            this.someQuestionnaireNoResume = false
            dataSeries = new QuestionPerformanceDataSeries(questionnaire.name, data)
          }
          
          this.questionPerformanceReport.addQuestionDataSeries(
            this.percentualLineGraph.params.kind,
            questionnaire,
            dataSeries
          )

          this.percentualLineGraph.addSeries(questionnaire.id, dataSeries)
        },
        (error) => {
          this.$vs.loading.close()
          console.error(error)
        }
      )
    },
    removeQuestionnaire(questionnaire) {
      this.percentualLineGraph.removeSeries(questionnaire.id)

      this.questionPerformanceReport.removeQuestionnaire(questionnaire)

      if (this.questionnaires.length === 0) {
        this.percentualLineGraph.setCategories([])
        this.questionPerformanceReport.questions = []
      }
    },
    reset() {
      if (this.percentualLineGraph) {
        this.percentualLineGraph.clear()
      }
      if (this.questionPerformanceReport) {
        this.questionPerformanceReport.questions = []
      }
    },
    updateData() {
      if (this.validFilters) {
        if (this.incompatibleQuestionnaires) {
          this.notifyError(this.$vs, this.$t('questionarios-incompativeis-selecione-qu'), 5000)
          return
        }

        this.percentualLineGraph.data.clear()
        this.questionPerformanceReport = QuestionsPerformanceReport.fromQuestionnaire(this.questionnaires[0].data)
        this.percentualLineGraph.setCategories(this.questionPerformanceReport.questions.map(
          (question, position) => {
            return this.$t('questao-position-1-0', [position + 1])
          }
        ))
        
        this.$vs.loading()
        this.questionnaires.forEach((questionnaireTag) => {
          this.addQuestionnaire(questionnaireTag.data)
        })
      }
    },
    questionTitle(question_id) {
      if (this.data && this.data.questions && this.data.questions.length > 0) {
        const foundQuestion = this.data.questions.findIndex((question) => question.id === question_id)
        if (foundQuestion !== -1) {
          return this.data.questions[foundQuestion].data.title
        }
      }

      return this.$t('questao-desconhecida')      
    },
    filtersChanged(change) {
      if (this.hasData) {
        this.reset()
      }
    },
    clearFilters() {
      const questionnaireSelect = this.$refs['select_questionnaire']
      if (questionnaireSelect) {
        questionnaireSelect.reset()
      }
      this.reset()
      this.selectedUsers = []
      this.averageKind = false
      this.questionnaires = []
    },
    getQuestionReportRowClass(item) {
      const base = 'flex flex-col gap-2'

      const invalidated = this._.get(item, 'questionData.invalidated.justification', false)
      if (invalidated !== false) {
        return `${base} invalidated-item`
      }

      return base
    }
  },

  beforeMount() {
    this.service = ReportService.build(this.$vs)
    this.questionnaireService = ContentQuestionnaireService.build(this.$vs)
    this.percentualLineGraph = new PercentualLineGraph()
    this.percentualLineGraph.yAxisName = this.$t('aproveitamento')
    this.userRepository = new ObjectRepository()
    this.userRepository.suggestableProperties = ['name', 'email']
    this.percentualLineGraph.params = {
      kind: 'score'
    }
  }
}
</script>